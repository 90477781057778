'use strict'

resizeFlg = {}
resizeFlg.pc = true
resizeFlg.sp = true
firstView = ''

# 初期メニュー高さ適応
applyInitialMenuHeight = (first)->
  if first is null
    first = false

  if first is true
    $accordionActiveItem = $('.js-accordion.is-open-first')
  else
    $accordionActiveItem = $('.js-accordion.is-open')

  $accordionActiveItem.each(
    ()->
      $accordionActiveContents = $(this).find('> .js-accordion-contents')
      $accordionActiveContentsInner = $accordionActiveContents.find('> .js-accordion-contents-inner')
      $accordionActiveContents.css({
        'height': $accordionActiveContentsInner.outerHeight()
        'maxHeight': $accordionActiveContentsInner.outerHeight()
      })

      if first is true
        $(this).removeClass('is-open-first')
        $(this).addClass('is-open')
  )

# PCのみ全て開く(.is-open-first 指定要素のみ)
if not window.matchMedia('(max-width:640px)').matches
  applyInitialMenuHeight(true)

$(window).on('load',
  ()->
    # アコーディオン開閉
    $('.js-accordion-button').on('click.accordion',
      ()->
        if not isFlick
          $parent = $root = $(this).closest('.js-accordion')
          $parent.removeClass('is-active')
          $parent.removeClass('is-close')
          $parent.toggleClass('is-open')
          parentContentsHeight = rootContentsHeight = $parent.find('> .js-accordion-contents > .js-accordion-contents-inner').outerHeight()

          if $parent.hasClass('is-open')
            $parent.find('> .js-accordion-contents').css({
              'height': parentContentsHeight
              'maxHeight': parentContentsHeight
            })
          else
            $parent.find('> .js-accordion-contents').removeAttr('style')

          if $parent.hasClass('is-open-all')
            $parent.toggleClass('is-open-all')
            $parent.addClass('is-close-all')
          else if $parent.hasClass('is-close-all')
            $parent.toggleClass('is-close-all')
            $parent.addClass('is-open-all')

          if $parent.hasClass('js-subaccordion')
            $root = $parent.parents('.js-accordion')
            rootContentsHeight = $root.find('> .js-accordion-contents > .js-accordion-contents-inner').outerHeight()

            if $root.hasClass('is-open') and $parent.hasClass('is-open')
              $root.addClass('is-open-all')
            else
              $root.removeClass('is-open-all')

            if $root.hasClass('is-open')
              if $root.hasClass('is-open-all')
                allContentsHeight = if parentContentsHeight isnt rootContentsHeight then rootContentsHeight + parentContentsHeight else parentContentsHeight
              else
                allContentsHeight = if parentContentsHeight isnt rootContentsHeight then rootContentsHeight - parentContentsHeight else parentContentsHeight

              $root.find('> .js-accordion-contents').removeAttr('style')
              $root.find('> .js-accordion-contents').css({
                'height': allContentsHeight
                'maxHeight': allContentsHeight
              })

          $parent.on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend',
            ()->
              if not $parent.hasClass('is-open')
                $parent.addClass('is-close')
          )
    )

    if window.matchMedia('(max-width:640px)').matches
      # SPの処理
      firstView = 'SP'
      # タップかフリックか判別
      touchPos = 0
      threshold = 10
      isFlick = false

      $('.js-accordion-button').on('touchstart',
        (e)->
          isFlick = false
          touchPos = e.originalEvent.touches[0].pageY
      )

      $('.js-accordion-button').on('touchmove',
        (e)->
          currentPos = e.originalEvent.touches[0].pageY

          if Math.abs(touchPos - currentPos) > threshold
            isFlick = true
          else
            isFlick = false
      )
    else
      firstView = 'PC'
)

$(window).on('resize',
  ()->
    clearTimeout(resizeTimer)

    resizeTimer = setTimeout(
      ()->
        if window.matchMedia('(max-width:640px)').matches
          if firstView is 'PC' and resizeFlg.sp is true or
          firstView is false and resizeFlg.sp is true
            # タップかフリックか判別
            touchPos = 0
            threshold = 10
            isFlick = false

            $('.js-accordion-button').on('touchstart',
              (e)->
                isFlick = false
                touchPos = e.originalEvent.touches[0].pageY
            )

            $('.js-accordion-button').on('touchmove',
              (e)->
                currentPos = e.originalEvent.touches[0].pageY

                if Math.abs(touchPos - currentPos) > threshold
                  isFlick = true
                else
                  isFlick = false
            )

            resizeFlg.pc = true
            resizeFlg.sp = false
            firstView = false
        else
          if firstView is 'SP' and resizeFlg.pc is true or
          firstView is false and resizeFlg.pc is true
            resizeFlg.pc = false
            resizeFlg.sp = true
            firstView = false

            applyInitialMenuHeight(true)
    , 250)
)
